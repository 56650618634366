/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Styled from '../../UserAccountPage.styles';
import Moment from 'react-moment';
import { ReactComponent as RightArrow } from '../../../../../../assets/icons/arrow.svg';
import moment from 'moment';
import useFetch from 'use-http';
import { useHistory } from 'react-router-dom';
const ComplexServiceDetails = () => {
  const history = useHistory();
  const research = history.location.state?.data;

  return (
    <Styled.DataContainer>
      <Styled.BackArrow>
        <RightArrow onClick={() => history.goBack()}></RightArrow>
        <Styled.VisitTitle>Paslaugų paketai</Styled.VisitTitle>
      </Styled.BackArrow>
      <Styled.InfoDetailsRow>
        <span>Data:</span>
        <div>
          {(new Date(Date.parse(research.date)).getUTCHours() === 0 && (
            <Moment utc={true} className="time" format="YYYY-MM-DD">
              {Date.parse(research.date)}
            </Moment>
          )) || (
              <Moment utc={true} className="time" format="YYYY-MM-DD HH:mm">
                {Date.parse(research.date)}
              </Moment>
            )}
        </div>
      </Styled.InfoDetailsRow>
      <Styled.InfoDetailsRow>
        <span>Pavadinimas:</span>
        <div>{research.serviceName}</div>
      </Styled.InfoDetailsRow>
      {research.sum && (
        <Styled.InfoDetailsRow>
          <span>Kaina:</span>
          <div>{research.sum}€</div>
        </Styled.InfoDetailsRow>
      )}
      {research.status && (
        <Styled.InfoDetailsRow>
          <span>Būsena:</span>
          <div>{research.validityStatus}</div>
        </Styled.InfoDetailsRow>
      )}

      <Styled.InfoDetailsRow>
        <span>Paketui priklausančios paslaugos</span>
      </Styled.InfoDetailsRow>

      {research.serviceParts?.length > 0 &&
        research.serviceParts.map((result, index) => {
          return (
            <div
              key={index}
              style={{
                marginLeft: '40px',
                backgroundColor: '#f1f5f7',
                maxWidth: '550px',
                borderRadius: '15px',
                padding: '10px',
                marginTop: '10px',
              }}
            >
              <Styled.InfoDetailsRow style={{ marginTop: '5px' }}>
                <span>Pavadinimas:</span>
                <div>{result.name}</div>
              </Styled.InfoDetailsRow>

              {result.objectServices?.length > 0 && (
                <Styled.InfoDetailsRow
                  style={{ marginTop: '5px', flexDirection: 'row' }}
                >
                  <span>Dedamosios dalys:</span>
                  <div>
                    {result.objectServices
                      .map((service) =>
                        service.name
                          .replace(' | AFF / MP', '')
                          .replace(' | AFF', '')
                          .replace(' | MP', ''),
                      )
                      .join(', ')}
                  </div>
                </Styled.InfoDetailsRow>
              )}

              <Styled.InfoDetailsRow
                style={{ marginTop: '5px', flexDirection: 'row' }}
              >
                <span>Kiekis:</span>
                <div>{result.quantity}</div>
              </Styled.InfoDetailsRow>

              <Styled.InfoDetailsRow
                style={{ marginTop: '5px', flexDirection: 'row' }}
              >
                <span>Nepanaudotas kiekis:</span>
                <div>{result.unusedQuantity}</div>
              </Styled.InfoDetailsRow>
              {result.unusedQuantity > 0 && (
                <Styled.InfoDetailsRow style={{ marginTop: '5px' }}>
                  <span>Galiojimo data:</span>
                  <div>
                    {' '}
                    <Moment utc={true} className="time" format="YYYY-MM-DD">
                      {Date.parse(result.validTo)}
                    </Moment>
                  </div>
                </Styled.InfoDetailsRow>
              )}
            </div>
          );
        })}
    </Styled.DataContainer>
  );
};
export default ComplexServiceDetails;
